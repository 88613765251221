import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dev',
  templateUrl: './dev.component.html',
  styleUrls: ['./dev.component.css']
})
export class DevComponent implements OnInit {

  title = 'MyOwnHomepage';
  data: any;

  constructor() { 
    
    this.data = {
      labels: ['Mental', 'Köperlich', 'Emotional', 'Selbst', 'Partner', 'Familie & Freunde', 'Zeit', 'Geld', 'Arbeit', 'Lernen', 'Spaß', 'Beitrag'],
      scales: [],
      datasets: [
          {
            label: 'Dataset1',
            min: 20,
            backgroundColor: 'rgba(179,181,198,0.2)',
            borderColor: 'rgba(179,181,198,1)',
            pointBackgroundColor: 'rgba(179,181,198,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(179,181,198,1)',
            data: [50, 20, 30, 30, 80, 30, 90, 20, 20 , 20, 50, 10]
          },
          {
            label: 'Dataset1',
            backgroundColor: 'rgba(179,181,198,0.2)',
            borderColor: 'rgba(179,181,198,1)',
            pointBackgroundColor: 'rgba(179,181,198,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(179,181,198,1)',
            data: [100, 100, 100, 100, 100, 100, 100, 100, 100 , 100, 100, 100]
        }
      ]
    };
  }

  ngOnInit() {
  }

}
