import { Component, OnInit } from '@angular/core';
import {trigger, style, animate, transition} from '@angular/animations';

@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.css'],
  animations: [
    trigger('fadein', [ 
      transition('void => *', [
        style({ opacity: 0 }), 
        animate(1000, style({opacity: 1}))
      ]) 
    ])
  ]
})
export class LandingpageComponent implements OnInit {

  constructor() { }
  isVisible: boolean = false;
  user: string;
  password: string;

  ngOnInit() {
  }

  enter() {
    this.isVisible = true;
  }

  login() {
    console.log(this.user);
    console.log("fine");
    this.user = "";
    this.password = "";
    console.log(this.user);
  }

}
